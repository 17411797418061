.eke-exerciseshow-graytitle:hover{
    color:#2bb473 ;
}
.eke-exerciseshow-graytitle{
    color:#4d4d4d;
    line-height: 1;
}

.eke-exerciseshow-tooltip {
    position: relative;
    display: inline-block;
    padding-bottom: 1.5rem;
}
.eke-exerciseshow-tooltip .eke-exerciseshow-tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: #2bb473 ;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0px 0;
    position: absolute;
    z-index: 1;
    bottom: 0.5em;
    margin-left:50rem;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.eke-exerciseshow-tooltip .eke-exerciseshow-tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 55%;
    right: 99%;
    margin-left: 0px;
    border-width: 6px;
    border-style: solid;
    border-color: #2bb473 transparent transparent transparent;
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
    -webkit-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    transform: rotate(80deg); 
}
.eke-exerciseshow-tooltip:hover .eke-exerciseshow-tooltiptext {
    visibility: visible;
    opacity: 1;
}

.eke-exerciseshow-similar .item {
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}

.eke-exerciseshow-similar .eke-exercisetile-div {
    -ms-flex: 1 1;
        flex: 1 1;    
    width: 300px;
}
