.emoji-picker
{
    width: 300px;
    height: 300px;
    padding: 0.5rem;    
}
.emoji-picker::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5; 
}
    
.emoji-picker::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: silver; 
}

.emoji-picker .emoji
{
    padding: 5px;
    border-radius: 3px;
}
.emoji-picker .emoji:hover
{
    background-color: lightgray;
}