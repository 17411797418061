.secuserselector-input-loading {
    background-color: gray;
}

ul.secuserselector {
    background: white;
    border: 1px solid #ddd;
    margin: 0;
}

ul.secuserselector > li {
    list-style-type: none;
    padding: 0.5em;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
}

ul.secuserselector > li:hover {
    background: #efefef;
}

span.secuserselector-item-email {
    color: green;
    width: 50px;
    overflow: hidden;
}

span.secuserselector-item-login {
    color: red;
    width: 50px;
    overflow: hidden;
}

span.secuserselector-item-id {
    font-family: monospace;
    color: magenta;
    width: 50px;
    overflow: hidden;
}

span.secuserselector-item-fullname {
    color: black;
    width: 50px;
    overflow: hidden;
}

span.secuserselector-item-noname {
    color: gray;
    width: 50px;
    overflow: hidden;
}

.sec-userinput-results
{
    overflow: hidden;
    border-top: 1px solid lightgray;
    width: 100%;
    max-width: 100%;
}

ul.sec-userinput-list {
    margin: 1rem 0 0;
    max-width: 100%;
}
ul.sec-userinput-list > li {
    list-style-type: none;
    padding: 0.5em;
    cursor: pointer;
    white-space: normal;
    max-width: 100%;
}
