.report-box {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin: 10px;
}
.report-title {
    width: 350px;
    margin-top: 2px;
}
.report-view-row {
    margin-right: 10px;
}
.report-view-export {
    margin-right: 10px;
}
.report-active-rowtitle {
    margin-top: 6px;
    font-size: 20px;
    margin-left: 10px;
}
.report-view-column {
    cursor: pointer;
}
.report-view-table {
    margin: 20px;
}
.reportDataRowDialogContent { padding: 0px !important;}
    .reportDataViewFrame{padding: 5px 10px;}
        .reportDataViewBox {display: -ms-flexbox;display: flex; margin-top: 4px; padding-right: 10px;}
            .reportDataViewLabel {width: 300px; text-align: right; vertical-align: top; padding: 0px;}
            .reportDataViewData {width: 100%; margin-left: 5px; vertical-align: top; font-weight: bold; padding: 0px;}
