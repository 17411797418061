.exercise-list-public{
    position: relative;
}

.exercise-list-public::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #e9eaea url(/img/LANDING/landing-main12.jpg) repeat-y center center/100%;
    opacity: 0.3;
    z-index: -1;
}

.exercise-list-tiles .column {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.exercise-list-tiles .eke-exercisetile-div {
    -ms-flex: 1 1;
        flex: 1 1;
}

.exerciseList-option
{
    font-size: 0.875rem !important;
    font-weight: normal;
    line-height: 1.8!important;
    color: #6f6f6f;

}
.exerciseList-maincomponent
{
    padding-top: 1.5rem;
}
.exerciselist-filterContainer
{  
   border:  #ddd  2px solid;
   background-color: #f5f5f5!important;
   display: -ms-flexbox!important;
   display: flex!important;
   padding: 10px 10px 0;
}

.ReactTable
{
    margin-bottom: 1rem;
}

.ReactTable input,
.ReactTable select,
.ReactTable button
{
    margin: 0;
}

.ReactTable .rt-th,
.ReactTable .rt-td{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}

.ReactTable .rt-th a,
.ReactTable .rt-td a{
    margin: 0;
}

.ReactTable .rt-th,
.ReactTable .rt-td.action-cell
{
    text-align: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.ReactTable .-pagination .-center
{
    -ms-flex: 1.5 1 200px;
        flex: 1.5 1 200px;
}

.exercise-list-page .result-count
{
    margin: 1rem 0;
}

.exercise-list-page .disabled-input{
    pointer-events: none;
}
