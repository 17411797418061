/*main slider*/

.orbit .orbit-controls .orbit-previous img {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.orbit .orbit-controls .orbit-previous:hover img {
    content: url('/img/LANDING/nyil_teli.png');
}

.orbit .orbit-controls .orbit-next:hover img {
    content: url('/img/LANDING/nyil_teli.png');
}

.main-callout {
    position: relative;
    overflow: hidden;
    padding: 1.2rem;
}

.main-callout .text h4 {
    font-weight: normal;
    margin-bottom: 1.2rem;
    line-height: 2;
}

.main-callout .text .bold {
    font-weight: bold;
}

.main-callout .button {
    border-radius: 2rem;
    background-color: #028144;
}

.main-callout .button:hover {
    background-color: #9e2685;
}

.main-callout .text {
    width: 65%;
}

.main-callout .figure {
    background-color: #2CA6CB;
    -webkit-transform: skew(-20deg);
        -ms-transform: skew(-20deg);
            transform: skew(-20deg);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}

.main-callout .figure img {
    margin-left: 8%;
    width: 35%;
    -webkit-transform: skew(20deg);
        -ms-transform: skew(20deg);
            transform: skew(20deg);
}

.eke-button-pastelblack {
    color: black !important;
    font-weight: bold;
}

/*------*/

section.main {
    padding: 2rem 0;
    position: relative;
}


section.main:first-child {
    margin-top: -1rem;
}

/*section.main:nth-child(2n+1){*/
section.main:nth-child(2n) {
    background-color: #e6e9ed;
}


section.main .head {
    position: relative;
    margin-bottom: 1rem;
}

section.main .head:before {
    content: '';
    position: absolute;
    left: 130px;
    bottom: 0;
    border-bottom: 2px solid #939393;
    width: calc(100% - 130px);
    max-width: 100%;
}

section.main:nth-child(2n) .head:before {
    border-color: #fff;
}

section.main .head h2 {
    font-family: 'BloggerSans', 'OpenSans', sans-serif;
    margin: 0;
    font-size: 1.8rem;
    font-weight: bold;
}

section.main .icon-tile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100px;
    height: 100px;
}

@media (max-width: 1024px) {
    section.main .icon-tile {
        width: 75px;
        height: 75px;
    }

    section.main .head:before {
        left: 120px;
    }
}

section.main .lead {
    font-family: 'BloggerSans', 'OpenSans', sans-serif;
    font-size: 150%;
}

.icons {
    background: url('/img/LANDING/icons.png') no-repeat;
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.icons.hover {
    background-image: none !important;
}

.icons.hover:before,
.icons.hover:after {
    content: '';
    background: url('/img/LANDING/icons.png') no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: 0.218s;
    -o-transition: 0.218s;
    transition: 0.218s;
}

.icons.hover:before {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
}

.icons.hover:hover:after {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
}

.icons.hover:hover:before {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.icons.icons-play-orange-o {
    background-position: -130px 0;
    width: 94px;
    height: 94px;
}

.icons.icons-play-o {
    background-position: -79px 0;
    width: 48px;
    height: 46px;
}

.icons.icons-task-calendar {
    background-position: -410px 1px;
    width: 54px;
    height: 58px;
}

.icons.icons-smartbook {
    background-position: -285px 0;
    width: 60px;
    height: 42px;
}

.icons.icons-media-library {
    background-position: -350px 0;
    width: 52px;
    height: 52px;
}

.icons.icons-task-calendar {
    background-position: -410px 1px;
    width: 54px;
    height: 58px;
}

.icons.icons-dashboard {
    background-position: -230px -57px;
    width: 58px;
    height: 58px;
}

.icons.icons-search {
    background-position: -290px -57px;
    width: 48px;
    height: 48px;
}

.icons.icons-good-exercise {
    background-position: -243px -116px;
    width: 34px;
    height: 54px;
}

.icons.icons-lesson-plan {
    background-position: -290px -120px;
    width: 55px;
    height: 48px;
}

.icons.icons-print-book {
    background-position: -350px -57px;
    width: 60px;
    height: 56px;
}

/*tankönyv választó*/

.main-head {
    font-family: 'BloggerSans', 'OpenSans', sans-serif;
    /*background: url('/img/LANDING/landing-main2.jpg') top center no-repeat;
    background-size: cover;*/
}

.main-head h1,
.main-head h2,
.main-head h3 {
    font-family: 'BloggerSans', 'OpenSans', sans-serif;
}

.main-head .video-link {
    position: relative;
    display: block;
}

.main-head .video-link .icons {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.main-head .video-link .icons:before {
    content: '';
    border: 2px solid #f43602;
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 100%;
    -webkit-transition: 0.218s;
    -o-transition: 0.218s;
    transition: 0.218s;
}

.main-head .video-link:hover .icons:before {
    -webkit-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
}

/* .main-head .main-search
  {
    padding-left: 1.5em;
  } */
.main-head .main-search input {
    font-size: 1.2rem;
}

.main-head .main-search input {
    font-size: 1.2rem;
}

.main-head .icon-tile {
    background-color: #0061b1;
    height: 100%;
}

.main-head .title {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
}

.main-head .title h2 {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
}

.main-head .title h3 {
    font-size: 1rem;
    color: #349a6b;
    font-weight: 500;
    margin-bottom: 0;
}

/* .main-head .choose-book
{
    padding-left: 1.5rem;
} */

/* .main-head .choose-book .lead
{
    font-size: 1.6rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
} */

.main-landing .orbit {
    position: absolute;
    top: 0;
    z-index: -1;
}

/* video */
section.main-video {
    background: url('/img/LANDING/landing-main2B.jpg') top center no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

/* section.main-video::before {
  content: "";
  background: url('/img/LANDING/landing-main2.jpg') top center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
} */
section.main-video .flex-video {
    -webkit-box-shadow: 1rem 1rem rgba(0, 0, 0, 0.3);
            box-shadow: 1rem 1rem rgba(0, 0, 0, 0.3);
}

section.main-video .video-link {
    position: relative;
    display: block;
}

section.main-video .video-link .icons {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/*statisztika sáv*/

section.main-numbers {
    /*background-color: #e6e9ed;*/
    padding: 2rem 0 0;
    overflow: hidden;
}

.main-numbers strong {
    display: block;
    font-size: 2rem;
    line-height: 1;
    font-weight: 500;
}

.main-numbers .stat-block {
    -ms-flex-pack: center;
        justify-content: center;
    display: -ms-flexbox;
    display: flex;
}

.main-numbers .stat-block+.stat-block {
    margin-bottom: 2rem;
    border-left: 1px solid #1a1a1a;
}

@media (max-width: 767px) {
    .main-numbers .stat-block:nth-child(2n+1) {
        border-left: 0px solid #1a1a1a;
    }
}

.main-numbers .row.collapse>.column {
    padding: 0 0.25rem;
}


/*Okostankönyveink*/
section.main-books {
    color: #fff;
}

section.main-books::before {
    background: url('/img/LANDING/main-book.jpg') top center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    content: "";
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

section.main-books::after {
    content: "";
    background-color: #4182b7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2;
}

.main-books .icon-tile {
    /* background-color: #0061b1; */
    background: url(/img/IKON_SET/FOOLDAL/okostankonyv.svg) no-repeat;
}

/*Feladattár*/
section.main-exercise {
    color: #fff;
}

section.main-exercise .icon-tile {
    /* background-color: #17b6b6; */
    background: url(/img/IKON_SET/FOOLDAL/feladattar.svg) no-repeat;
}

section.main-exercise::before {
    background: url('/img/LANDING/main-book.jpg') top center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    content: "";
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

section.main-exercise::after {
    content: "";
    background-color: #208686;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2;
}

/*Médiatár*/
section.main-media {}

section.main-media::before {
    background: url('/img/LANDING/main-media.jpg') top center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    content: "";
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

section.main-media::after {
    content: "";
    background-color: #499088;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2;
}

.main-media .icon-tile {
    /* background-color: #f43602; */
    background: url(/img/IKON_SET/FOOLDAL/mediatar.svg) no-repeat;
}



/*Keresés*/
.main-search .icon-tile {
    /* background-color: #f43602; */
    background: url(/img/IKON_SET/FOOLDAL/kereso.svg) no-repeat;
}

/*Dashboard*/
section.main.main-dashboard .head:before {
    border-color: #0a0a0a;
}

.main-dashboard .icon-tile {
    /* background-color: #23b6ce; */
    background: url(/img/IKON_SET/FOOLDAL/szemelyre_szabhato_iranyitopult.svg) no-repeat;
}

/*Jó gyakorlatok*/
.main-good-practice .icon-tile {
    /* background-color: #2f7c11; */
    background: url(/img/IKON_SET/FOOLDAL/jogyakorlat.svg) no-repeat;
}

/*Óravázlat*/
section.main-lesson-plan {
    color: #fff;
}

section.main-lesson-plan::before {
    background: url('/img/LANDING/main-lessonplan.jpg') top center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    content: "";
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

section.main-lesson-plan::after {
    content: "";
    background-color: #4182b7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2;
}

.main-lesson-plan .icon-tile {
    background-color: #0061b1;
}

/*Landing*/
section.main-landing {
    padding-top: 4%;
    padding-bottom: 100px;
    min-height: calc(100vh - 75px);
    position: relative;
    background: transparent !important;
    /*background: url('/img/LANDING/landingsea.png') top center no-repeat;*/
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@media(max-width: 1310px) {
    section.main-landing {
        padding-top: 170px;
    }
}

@media(max-width: 639px) {
    section.main-landing {
        padding-top: 4%;
    }
}

section.main-landing h3 {
    color: #fff;
    margin: 1rem 0;
}

section.main-landing .eke-search-field {
    margin-bottom: 2rem;
}


section.main-landing .slide1,
section.main-landing .slide2,
section.main-landing .slide3 {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

section.main-landing .slide1 {
    background: url('/img/LANDING/nkp_landingpage.png')no-repeat center;
    background-size: cover;    
    /* background-attachment: fixed; */
}

section.main-landing .slide1.show-books {
    background: url('/img/LANDING/landing_bg.png')no-repeat center;
    background-attachment: fixed;
    background-size: cover;
}

@-webkit-keyframes fade {

    0%,
    30% {
        opacity: 1
    }

    35%,
    60% {
        opacity: 0
    }

    65%,
    90% {
        opacity: 1
    }

    95%,
    100% {
        opacity: 1
    }
}

@keyframes fade {

    0%,
    30% {
        opacity: 1
    }

    35%,
    60% {
        opacity: 0
    }

    65%,
    90% {
        opacity: 1
    }

    95%,
    100% {
        opacity: 1
    }
}

@-webkit-keyframes fade2 {

    0%,
    30% {
        opacity: 0
    }

    33%,
    60% {
        opacity: 1
    }

    65%,
    90% {
        opacity: 0
    }

    95%,
    100% {
        opacity: 0
    }
}

@keyframes fade2 {

    0%,
    30% {
        opacity: 0
    }

    33%,
    60% {
        opacity: 1
    }

    65%,
    90% {
        opacity: 0
    }

    95%,
    100% {
        opacity: 0
    }
}

@-webkit-keyframes fade3 {

    0%,
    30% {
        opacity: 0
    }

    33%,
    60% {
        opacity: 0
    }

    65%,
    90% {
        opacity: 1
    }

    95%,
    100% {
        opacity: 0
    }
}

@keyframes fade3 {

    0%,
    30% {
        opacity: 0
    }

    33%,
    60% {
        opacity: 0
    }

    65%,
    90% {
        opacity: 1
    }

    95%,
    100% {
        opacity: 0
    }
}

/* section.main-landing::after {
  content: "";
  background-color: #094C77;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -2;   
} */
/*
section.main-landing::before {
  content: "";
  background: url('/img/LANDING/landing-main12.jpg') top center no-repeat;
  background-size: cover;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;   
}*/

section.main-landing .lead {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    text-align: center;
}

section.main-landing .view-video {
    position: absolute;
    bottom: 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    width: 100%;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

section.main-landing .view-video i {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

section.main-landing .view-video:hover i {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: translateY(5px);
        -ms-transform: translateY(5px);
            transform: translateY(5px);
}

.main-landing h1,
.main-landing h2,
.main-landing h3 {
    font-family: 'BloggerSans', 'OpenSans', sans-serif;
}

.main-landing .title {
    margin: 2rem 0;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

.main-landing .title a {
    color: #4BC58A;
}

@media (max-width: 860px) {
    .main-callout .figure {
        right: -60%;
    }

    .main-callout .figure img {
        width: 26%;
    }
}

@media (max-width: 639px) {
    .main-callout .text {
        width: 100%;
    }

    .main-callout .figure {
        display: none;
    }
}

/* dl carousel */
.dl-carousel {
    position: relative;
}

.dl-carousel .owl-stage-outer {
    background: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.dl-carousel .item {
    border-radius: 3px;
    padding: 3px;
}

.dl-carousel .item .inner {
    border-radius: 3px;
    background: #ffffff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.dl-carousel .item .book-item {
    height: 300px;
}

.dl-carousel .item .book-item .item-title {
    margin: 0.5rem 1rem;
}

.dl-carousel .item .book-item figure {
    height: 200px;
    overflow: hidden;
    height: 200px;
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    overflow: hidden;
}

.dl-carousel .item .book-item figure img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
}

.dl-carousel .owl-nav>button.owl-next,
.dl-carousel .owl-nav>button.owl-prev {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 2rem !important;
    padding: 0.5rem !important;
    border-radius: 0;
    margin: 0;
}

.dl-carousel .owl-next {
    right: 3px;
}

.dl-carousel .owl-prev {
    left: 3px;
}

.dl-carousel button.owl-next:hover,
.dl-carousel button.owl-prev:hover {
    background-color: rgba(50, 50, 50, 0.5) !important;
}