.listview-button {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin: 2px !important;
    padding-top: 0.80em;
    padding-right: 0.95em;
    padding-bottom: 0.80em;
    padding-left: 0.95em;
    border: 1px solid transparent;
    border-radius: 3px;
    -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    -o-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.85rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #0F669E;
    color: #fefefe;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.65);
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.65);
}

.listview-button-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.listview-button-alert {
    background-color: #cc4b37;
    color: #fefefe;
}


.listview-button-warning {
    background-color: #ffae00;
    color: #0a0a0a;
}


.listview-button-success {
    background-color: #3adb76;
    color: #0a0a0a;
}


.listview-button-secondary {
    background-color: #767676;
    color: #f3f3f3;
}

.listview-button-primary {
    background-color: #1779ba;
    color: #f3f3f3;
}




