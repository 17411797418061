.eke-fillgap-text {
     padding: 1em;
 }
 .eke-fillgap-text-p {
     line-height: 210%;
     text-align: left;
     font-size: 90%;

     background: rgba(255, 255, 255, 0.8);
     padding: 2%;
     border-radius: 0.3vw;
     -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
             box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
 }

.eke-fillgap-text-img {
    max-height: 300px;
    max-width: 600px;
    border-radius: 0.3vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
 }

 .eke-fillgap-text-inputtext {
    display: inline-block;
    min-width: 1em;
    height: 2em;
    font-size: 90%;
    min-height: 1.65rem;
    border-width: 2px;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-right: .5em;
    margin-left: .5em;
    margin-bottom: 0em;
    padding-top: .2rem;
    padding-bottom: .2rem;
 }

 .eke-fillgap-text nav {
    margin: 0.5em;
 }
 