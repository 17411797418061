.ReactTags__selected a.ReactTags__remove {
    color: #2bb473 !important;
    padding: 2px;
    font-size: 12pt;
}

.ReactTags__tag {
    padding: 5px !important;
}
/* 
.button.save,
.button.reload {
    margin: 3px;
    padding: 10px;
    display: inline-block;
    border: 2px #2bb473 solid;
    background-color: #fff;
} */

.button.reload:hover {
    background-color: #2bb473;
    color: #fff;
}

/* .button.save:hover {
    background-color: #2bb473;
    color: #fff;
} */

.ReactTags__tagInputField {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    border: rgba(43, 180, 115, 0.6) solid 1px !important;
}

/* .button.save::after {
    content: "\F0C7";
    font-family: "Font Awesome 5 Free";
    color: #2bb473;
    position: relative;
    font-size: 20px;
    top: 2px;
    left: 5px;
    font-weight: 100;
} */
/* 
.button.reload::after {
    content: "\f021";
    font-family: "Font Awesome 5 Free";
    color: #2bb473;
    position: relative;
    font-size: 20px;
    top: 2px;
    left: 5px;
    font-weight: 100;
}

.button.save:hover::after {
    content: "\F0C7";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    position: relative;
    font-size: 20px;
    top: 2px;
    left: 5px;
    font-weight: 100;
} */

/* .button.reload:hover::after {
    content: "\f021";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    position: relative;
    font-size: 20px;
    top: 2px;
    left: 5px;
    font-weight: 100;
} */
/* 
.button.save:active,
.button.reload:active {
    background-color: #1b8553;
    color: #fff;
}

.button.save:active::after {
    content: "\F0C7";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    position: relative;
    font-size: 20px;
    top: 2px;
    left: 5px;
    font-weight: 100;
}

.button.reload:active::after {
    content: "\f021";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    position: relative;
    font-size: 20px;
    top: 2px;
    left: 5px;
    font-weight: 100;
}

.button.save:focus,
.button.reload:focus {
    background-color: #2bb473;
    color: #fff;
    outline: none;
}

.button.save:focus::after {
    content: "\F0C7";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    position: relative;
    font-size: 20px;
    top: 2px;
    left: 5px;
    font-weight: 100;
}

.button.reload:focus::after {
    content: "\f021";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    position: relative;
    font-size: 20px;
    top: 2px;
    left: 5px;
    font-weight: 100;
} */
.meta-category-assigner-selected-category {
    padding: 6px;
    margin: 3px;
}
