.Knowledgebase-maincomponent {
    padding-top: 1.5rem;
    padding-left: 1em!important;
}
.Knowledgebase-list-page {
    max-width: 100rem !important;
    margin: 30px, 5px !important;
   
}
.knowledge-base-notification
{
    display: none;
}