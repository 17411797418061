
.fixed-wrapper *
{
    font-size: 16px;
}

.fixed-wrapper .fixed {
    position:fixed;
    left: 0;
    bottom: 45%;
    color: white;
    font-weight: bold;
    font-family: "Font Awesome 5 Free";
    z-index: 800;
}
.chevronpadder{
    float: right;
}

.toolbar{
    -webkit-transform: translateX(-45px);
        -ms-transform: translateX(-45px);
            transform: translateX(-45px);
    -webkit-transition: 100ms;
    -o-transition: 100ms;
    transition: 100ms;
}
.toolbar:hover{
    -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-transition: 100ms;
    -o-transition: 100ms;
    transition: 100ms;
}
.toolbar:hover .toolbar-handle{
    opacity: 0;
}

.toolbar-handle
{
    width: 15px;
    height: 50px;
    background-color: #ccc;
    border-radius: 3px 3px 3px 3px;
    top: 50%;
    border-radius: 0px 3px 3px 0px;
    position: absolute;
    margin-top: -22px;
    right: -20px;
    text-align: center;
    -webkit-box-shadow: 1px 1px 2px rgb(0,0,0, 0.8);
            box-shadow: 1px 1px 2px rgb(0,0,0, 0.8);
}
.toolbar-handle i
{
    font-size: 1.2em;
    color: #333;
    line-height: 50px;
}

.toolbar .tools .tab
{
    display: block;
    cursor: pointer;
    font-size: 1.2em;
    overflow: hidden;
    background-color: #777;
    border-radius: 0px 5px 5px 0px;
    -webkit-box-shadow: 1px 1px 2px rgb(0,0,0, 0.6);
            box-shadow: 1px 1px 2px rgb(0,0,0, 0.6);
    min-width: 30px;
}

.toolbar .tools i,
.toolbar .tools .tab span
{

    padding: 0.4em;
    background-color: #777;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

.toolbar .tools i:hover
{
    background-color: #999;
}

.toolbar input[type=radio],
.toolbar input[type=checkbox]
{
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
.toolbar input[type=radio]:checked ~ i,
.toolbar input[type=checkbox]:checked ~ i
{    
    opacity: 1;
    background-color: #203138;
}

.toolbar ul {
    margin: 0 }

.toolbar ul li {
    padding: 0 }

.toolbar ul li .tab-menu{
    display: -ms-flexbox;
    display: flex; 
    position: relative;
}

.toolbar ul li .tab-menu > .tab{  
    position: absolute;
    border-radius: 5px;
}
.toolbar:hover ul li .tab-menu > .tab{  
    position: static;
    margin-right: 3px;
}   
.toolbar ul li .tab-menu > .tab:first-child{  
    position: static;
    border-radius: 0px 5px 5px 0px;
}

.toolbar ul li .tab{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 5px;
    width: 40px;
    -webkit-transition: 100ms;
    -o-transition: 100ms;
    transition: 100ms;
    text-align: center;
 }
 .toolbar ul li .tab label{
    text-align: center;
    width: 100%;
 }

 /* toolbox */

 .toolbox
 {
     color:#fff;
     background-color: rgb(0,0,0, 0.9);
     padding: 1rem;
     -webkit-box-shadow: 1px 0px 5px -1px rgb(0,0,0, 0.6);
             box-shadow: 1px 0px 5px -1px rgb(0,0,0, 0.6);
     z-index: 800;
     width: 200px;
     position: absolute;
     top:100px;
     left:100px;
    }

.prev-side, .next-side
{
    background-color: #333;
    position: fixed;
    width: 100px;
    z-index: 700;
}
.prev-side
{
    top:0;
    left:0;
    bottom: 0;
}
.next-side
{
    top:0;
    right:0;
    bottom: 0;
}


.pager-prev
{
    border-radius: 80px 0 0 80px;
    background-color: #fff;
    position: absolute;
    bottom:50%;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
    right: 0;
    z-index: 700; /*$Z_INDEX_BOOK_STICKY : 800;*/
    height: 160px;
    width: 80px;
}
.pager-prev::after
{
    content: '';
    border-color: transparent red transparent transparent;
    border-style: solid;
    border-width: 50px;
    position: absolute;
    bottom:50%;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
    right: 0;
}

.pager-prev .triangle-arrow
{
    color: green;
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg);
    overflow: visible;
}
.pager-next
{
    border-radius: 0 80px 80px 0;
    background-color: #fff;
    position: absolute;
    bottom:50%;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
    left: 0;
    z-index: 700; /*$Z_INDEX_BOOK_STICKY : 800;*/
    height: 160px;
    width: 80px; 
}
.pager-next::after
{
    content: '';
    border-color: transparent transparent transparent blue;
    border-style: solid;
    border-width: 50px;
    position: absolute;
    bottom:50%;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
    left: 0;
}
.pager-next .triangle-arrow
{
    color: green;
    -webkit-transform: rotate(-30deg);
        -ms-transform: rotate(-30deg);
            transform: rotate(-30deg);
    overflow: visible;
}
.simple #content{
    position: fixed;
    top:0;/*140px;*/
    bottom: 0;
    left:120px;
    right: 120px;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;

}

.section-slider
{
    bottom:0;
    left:0;
    right:0; 
    z-index: 850;
    background-color: #777;
}
.section-slider .thumb-container
{
    display: "flex";
}
.section-slider .section-thumb
{
    width: 300px;
    height: 200px;
    background-color: #999;
}

@media (max-width: 639px) {

    .pager-prev
    {
        top: unset;
        bottom: 1rem;
        height: 55px;
        width: 55px;
        left:15px;
    }
    .pager-next
    {
        top: unset;
        bottom: 1rem;
        height: 55px;
        width: 55px;
        right: 15px;
    }
    .simple.scroll-top
    {
        bottom: 115px;
    }
}
  