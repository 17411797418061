i.checkbox {
    padding: 0.2em;
    font-size: 1.2em;
    border: 0.1em solid;
    border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    -webkit-border-radius: 0.3em;
    margin-right: 1rem;
    position: relative;
    overflow: hidden;
}
i.checkbox.uncheck:before
{
    content: '';
}
i.checkbox:before
{
    display: block;
    height: 1em;
    width: 1em;
    text-align: center;
}


i.checkbox-disabled {
    color: #aaaaaa;
    cursor: not-allowed;
}

i.checkbox-enabled {
    cursor: pointer;
}