.form
{
    margin-bottom: 1rem;
}
/*
.form label:nth-child(2),
.form label:nth-child(4){
    padding-top: 1rem;
}*/

.form .form-label{
    font-size: 0.9rem;
}

.forminput
{
    /*border: 1px solid lightgray;
    border-top: 0px;
    margin-top: -1px;
    background-color: #efefef;*/
    padding-bottom: 1rem;
    margin: 0;   
}

.forminput input
{
    margin: 0;
    font-size: 0.8rem;
    line-height: 1;
    max-height: 30px;
}
/*.forminput:nth-child(2),
.forminput:nth-child(4)
{
    border-top: 1px solid lightgray;
    padding-top: 1rem;
}*/

.forminput-three-state-checkbox{
    border-top: 1px solid lightgray;
}

.form .radio-button-label{
    margin-right: 1.5rem;
    display: inline-block;
}

.forminput-missing input {
    background-color: #ffcccc;
}

.forminput-valid input{
    background-color: #ccffcc;
}

.forminput-empty input{
    background-color: #ffffff;
}

.forminput-label-error {
    color: #aa4444;
}

.forminput-label-warning {
    color: #777744;
}

.forminput-label-ok {
    color: #44aa44;
}

.forminput-label-empty {
    color: #444444;
}

.profile_form_separator{
    border-bottom: 1px solid gray;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
}

.profile_disabled_input{
    min-height: 2.4375rem;
    margin: 0 0 1rem;
    padding: 0.5rem;
    border: 1px solid #cacaca;
    border-radius: 3px;
    background-color: #eee;
    -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
}
