/* CountdDown base */
.circle-graph {
    display: inline-block;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: #8a8a8a;
    position: relative;
}

.circle-graph--interrupt {
    cursor: pointer;
}

.circle-graph.gt-50 {
    background-color: #1779ba;
}

.circle-graph-progress {
    content: "";
    position: absolute;
    border-radius: 50%;
    left: calc(50% - 3rem);
    top: calc(50% - 3rem);
    width: 6rem;
    height: 6rem;
    clip: rect(0, 6rem, 6rem, 3rem);
}

.circle-graph-progress .circle-graph-progress-fill {
    content: "";
    position: absolute;
    border-radius: 50%;
    left: calc(50% - 3rem);
    top: calc(50% - 3rem);
    width: 6rem;
    height: 6rem;
    clip: rect(0, 3rem, 6rem, 0);
    background: #222;
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg);
}

.gt-50 .circle-graph-progress {
    clip: rect(0, 3rem, 6rem, 0);
}

.gt-50 .circle-graph-progress .circle-graph-progress-fill {
    clip: rect(0, 6rem, 6rem, 3rem);
    background: #8a8a8a;
}

.circle-graph-percent {
    content: "";
    position: absolute;
    border-radius: 50%;
    left: calc(50% - 5rem/2);
    top: calc(50% - 5rem/2);
    width: 5rem;
    height: 5rem;
    background: #e6e6e6;
    text-align: center;
    display: table;
    z-index: 4;
}

.circle-graph-percent .circle-graph-percent-number {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    color: #1779ba;
    margin-bottom: 2px;
}
.circle-graph-percent .circle-graph-percent-number-shared {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    color: #1779ba;
    margin-bottom: 2px;
}

.circle-graph-percent-wrapper {
    display: table-cell;
    vertical-align: middle;
    line-height: 0;
}

.circle-graph-percent-wrapper span {
    line-height: 1;
}

.circle-graph-percent-button {
    background: none;
    font-weight: bold;
    padding: 0;
    border: none;
    border-radius: 30px;
    margin: 0;
    font-size: 0.875rem;
    color: #000000;
    outline: none;
    cursor: pointer;
    text-align: left;
}

.circle-graph-percent-button:hover, .circle-graph-percent-button:active, .circle-graph-percent-button:focus {
    background: none;
    border: none;
}

.circle-graph-percent-button i {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}