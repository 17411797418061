.lp-wrapper{
    visibility: visible;
}
.extension-unit-wrapper {
    width: 150px;
    max-height: 130px;
    display: inline-block;
    padding: 6px;
    margin: 2px;
    text-align: center;
    vertical-align: bottom;
}
