.fc-rtl .fc-visit-list-view {direction: rtl;}
.fc-ltr .fc-visit-list-view {direction: ltr;}
.fc .fc-visit-list-table {table-layout: auto; border-collapse: inherit; width: auto; border-spacing: 30px 10px;}
.fc-visit-list-table tr {border-collapse: inherit;}
.fc-visit-list-table tr:nth-child(even) {background-color: inherit;}
.fc-visit-list-table td {padding: 8px 14px; border: none;}

.fc-visit-list-heading-col {font-weight: bold; border: none !important;}
.fc-visit-list-item-event {border: none !important;}
.fc-ltr .fc-visit-list-heading-main {float: left;}
.fc-rtl .fc-visit.list-heading-main {float: right;}
.fc-visit-list-heading-main {margin: 10px; border-bottom: 4px solid cornflowerblue;  padding-top: 20px;}
.fc-visit-list-heading-link {text-decoration: none; padding: 4px 10px; color: black; font-weight: bold; font-size: 20px;}
.fc-visit-list-heading-link:hover {background-color: cornflowerblue; color: white; font-style: normal; font-weight: bold; text-decoration: none !important;}

.fc-visit-list-item.fc-has-url {cursor: pointer;}
.fc-event-dot {display: inline-block; width: 10px; height: 10px; border-radius: 5px;}
.fc-visit-list-item-marker,.fc-visit-list-item-time {white-space: nowrap; width: 1px;}
.fc-visit-list-item {cursor: pointer;}
.fc-ltr .fc-visit-list-item-marker { padding-right: 0; }
.fc-rtl .fc-visit-list-item-marker { padding-left: 0; }

.fc-visit-list-empty {padding: 40px 20px; background-color: inherit; text-align: center; color: maroon; font-weight: bold; font-size: 24px}
