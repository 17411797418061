@font-face {
    font-family: 'BloggerSans';
    src: url('/fonts/BloggerSans/BloggerSans.ttf');
}

@font-face {
    font-family: 'BloggerSansBold';
    src: url('/fonts/BloggerSans/BloggerSans-Bold.ttf');
}

.eke-exercisetile-div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    border: 10px solid #fff;
    /*padding: 10px;*/
    border-radius: 3rem;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .6);
            box-shadow: 0 2px 6px rgba(0, 0, 0, .6);
    margin: 20px;
    /* height: auto; */
    min-width: 100px;
    min-height: 100px;
    background-color: #77DAAA;
    position: relative;
}

.eke-exercisetile-div:hover {
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
    z-index: 2;
    position: relative;
}

.eke-exercisetile-div:after {
    content: '';
    display: block;
    position: absolute;
    /* top: 15%; */
    margin-top: 15%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0%;
    background-color: #4BC58A;
    border-radius: 2.5rem;
    z-index: 0;
}

.column.medium-4.small-12.has-tip {
    padding-left: 0px;
    padding-right: 0px;
}

@media (min-width: 640px) {
    .eke-exercisetile-div {
        min-height: 270px;
    }
}

.eke-exercisetile-inner-div {
    padding: 17% 7px 7px 7px;
    height: auto;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex: 1 1;
        flex: 1 1;
    position: relative;
    z-index: 1;
    -ms-flex-pack: center;
        justify-content: center;
}

.eke-exercisetile-inner-div h3 {
    color: #333;
}

.eke-exercisetile-inner-div .level {

    border-radius: 80px 80px 0 0;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    width: 85px;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    background-color: #fefefe;
    bottom: 0;
    /* position: absolute; */
    border: 2px solid #fff;
    margin-top: auto;
    margin-bottom: -8px;
}

.eke-exercisetile-inner-div .level img {
    width: 80px;
    padding: 10px;
    border-radius: 0.5rem;
    margin-top: auto;
    margin-bottom: 0.5rem;
}

.eke-exercisetile-inner-div .badge {
    font-size: 1rem;
}

.eke-exercisetile-thumbnail {
    border-radius: 0.3vw;
    margin: 7px;
    max-height: 90px;
}

.eke-exercisetile-contentdiv {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    font-family: 'BloggerSansBold';
    color: #fff;
    font-size: 1.2rem;
    /*text-shadow: 0 1px 2px rgba(0, 0, 0, .5), 0 0px 3px rgba(0, 0, 0, .75);*/
}


.owl-stage-outer {
    background-color: whitesmoke;
    -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, .24);
            box-shadow: inset 0 2px 5px rgba(0, 0, 0, .24);
}

.owl-nav>button {
    font-size: -webkit-xxx-large !important;
}

/*owlCarousel fix*/
/*
@media (max-width: 250px) {
    .owl-item {
        width: 200px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip {
        width: 200px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip>.eke-exercisetile-div {
        height: 250px !important;
    }
}

@media (max-width: 399px) and (min-width: 241px) {
    .owl-item {
        width: 250px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip {
        width: 250px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip>.eke-exercisetile-div {
        height: 230px !important;
    }
}


@media (max-width: 459px) and (min-width: 400px) {
    .owl-item {
        padding-left: 10px;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip {
        width: 180px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip>.eke-exercisetile-div {
        height: 250px !important;
    }
}

@media (max-width: 600px) and (min-width: 460px) {
    .owl-item {
        padding-left: 10px;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip {
        width: 150px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip>.eke-exercisetile-div {
        height: 315px !important;
    }
}

@media (max-width: 899px) and (min-width: 601px) {
    .owl-item {
        padding-left: 10px;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip {
        width: 140px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip>.eke-exercisetile-div {
        height: 320px !important;
    }
}

@media (max-width: 1599px) and (min-width: 900px) {
    .owl-item {
        padding-left: 10px;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip {
        width: 150px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip>.eke-exercisetile-div {
        height: 315px !important;
    }
}

@media (min-width: 1600px) {
    .owl-item {
        padding-left: 10px;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip {
        width: 180px !important;
    }

    .owl-item>.item.eke-exerciseshow-exe-tile.has-tip>.eke-exercisetile-div {
        height: 250px !important;
    }
}
*/