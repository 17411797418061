ul.wf-head-plugin-actual {
    list-style-type: none;
}

.wf-head-plugin-actual-station {
    border: 2px 2px 2px 2px;
    margin: 4px 4px 4px 4px;
    padding: 4px 4px 4px 4px;
    border-color: black;
    border-width: 2px 2px 2px 2px;
    border-radius: 10px;
    font-size: 1.3em;
}

.wf-head-plugin-transition-button {
    border: 2px 2px 2px 2px;
    margin: 4px 4px 4px 4px;
    -webkit-box-shadow: 3px 3px #888888;
            box-shadow: 3px 3px #888888;
    border-color: black;
    border-width: 2px 2px 2px 2px;
    border-radius: 10px;
}

.wf-head-plugin-publish-button {
  background-color: green;
}

.wf-head-plugin-unpublish-button {
  background-color: red;
  color: white;
}

.wf-head-plugin-fieldset {
    height: 100%;
}

.wf-head-plugin-row {
   padding: 15px;
}

/* -------- Step progressbar style ----------- */

.progress-indicator {
    list-style: none;
    width: 100%;
    margin-bottom: 30px;
    padding: 0;
    display: table;
    table-layout: fixed;
  }
  
  .progress-indicator > li {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 1.5em;
  }
  
  .progress-indicator > li span {
    position: absolute;
    color: #aaaaaa;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.05px;
    text-transform: uppercase;
  }
  
  .progress-indicator > li::before {
    content: attr(data-step);
    display: block;
    margin: 0 auto;
    background: #aaaaaa;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    margin-bottom: 0.25em;
    line-height: 1.5em;
    border-radius: 100%;
    position: relative;
    z-index: 2;
  }
  
  .progress-indicator > li::after {
    content: '';
    position: absolute;
    display: block;
    background: #aaaaaa;
    width: 100%;
    height: 0.15em;
    top: 50%;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
    left: 50%;
    margin-left: 1.5em\9;
    z-index: 0;
  }
  
  .progress-indicator > li:last-child:after {
    display: none;
  }
  
  .progress-indicator > li.is-complete {
    color: #1779ba;
  }
  
  .progress-indicator > li.is-complete::before, .progress-indicator > li.is-complete::after {
    color: #fefefe;
    background: #1779ba;
  }
  
  .progress-indicator > li.is-complete span {
    color: #1779ba;
  }
  
  .progress-indicator > li.is-current {
    color: #4eabe9;
  }
  
  .progress-indicator > li.is-current::before {
    color: #fefefe;
    background: #4eabe9;
  }
  
  .progress-indicator > li.is-current span {
    color: #4eabe9;
  }
  
  