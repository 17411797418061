.lpChapterLesson-list {
    width: auto;
    margin: 2em;
}

.lpChapterLesson-list .lpChapterLesson-chapter-inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-align: center;
        align-items: center;
    background-color: #eee;
    padding-top: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
}

.lpChapterLesson-list .lpChapterLesson-chapter-inner .button {
    margin-bottom: 0;
}

.lpChapterLesson-list .lpChapterLesson-header {
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
}

.lpChapterLesson-list .lpChapterLesson-chapter-name {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin: 0.5em;
}

.lpChapterLesson-list .lpChapterLesson-lesson-inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: start;
        justify-content: flex-start;
    margin-left: 3em;
}

.lpChapterLesson-list .lpChapterLesson-lesson-name {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin: 0.5em;
}