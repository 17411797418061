.fc-list-empty {color: red; font-size: 24px; font-weight: bold;}
.fc-day-grid {overflow: hidden}
.fc-day-grid-event {padding: 0px !important;}

.eventCalendarTitle {background-color:#16a7e0; color: white; font-size:24px; text-align:center; padding:10px; margin:5px; font-weight:bold;}

.eventCalendarTooltipContainer {position: absolute; display: inline-block; border-bottom: 1px dotted #222;}
.eventCalendarTooltipContainer .eventCalendarTooltipText {background-color: #222; color: #fff; opacity: 1; 
    text-align: center; border-radius: 6px; padding: 5px 0; position: absolute; z-index: 1;
    bottom: 150%; left: 50%; }
.eventCalendarTooltipContainer .eventCalendarTooltipText::after {content: ""; position: absolute; top: 100%; left: 50%; margin-left: -5px; 
    border-width: 5px; border-style: solid; border-color: #222 transparent transparent transparent;}

.eventCalendarMenuContainer {position: absolute; background-color: white; color: black; z-index: 9000; border: solid 1px gray; text-align: left; min-width: 15em;}
.eventCalendarMenuTitle {background-color: #16a7e0; color: white; text-align: center; font-size: 15px; font-weight: bolder; padding: 10px; cursor:-webkit-grab; cursor:grab;}
.eventCalendarMenuList {list-style: none; margin: 0; overflow: hidden; width: -webkit-max-content; width: -moz-max-content; width: max-content; width: 100%;}
.eventCalendarMenuText {font-size: 14px; margin:2px; height: 32px; padding: 5px 10px 2px 10px;}
.eventCalendarMenuText:hover {background-color: #333; cursor: pointer; color: white;}
.eventCalendarMenuDisabled {pointer-events:none; opacity:0.3;}


.fc-prevYear-button,
.fc-prev-button,
.fc-next-button,
.fc-nextYear-button
{
    font-size: 0;
    padding: 0.4rem 0.65rem;
    border-radius: 0.25rem;        
}
.fc-prevYear-button:not(:first-child),
.fc-prev-button:not(:first-child),
.fc-next-button:not(:first-child),
.fc-nextYear-button:not(:first-child)
{
    margin-left: 0.75rem;
}

.fc-prevYear-button:before,
.fc-prev-button:before,
.fc-next-button:before,
.fc-nextYear-button:before
{
    vertical-align: middle;
    text-align: center;
    width: 1rem;
    height: 1rem;
    font-family: 'fcicons' !important;
    font-size: 1.5rem;
    line-height: 1;
}


.fc-prevYear-button:before {
    content: "\e902";    
}

.fc-prev-button:before {
    content: "\e900";
}

.fc-next-button:before {
    content: "\e901";
}

.fc-nextYear-button:before
{
    content: "\e903";
}

.fc-visit-list-item-box {display: -ms-flexbox;display: flex; -ms-flex-flow: row nowrap; flex-flow: row nowrap; width: -webkit-max-content; width: -moz-max-content; width: max-content; height: 80px;}
.fc-visit-list-item-image {width: 80px; height: 100%;}
.fc-visit-list-item-content {display: -ms-flexbox;display: flex; -ms-flex-flow: row nowrap; flex-flow: row nowrap; height: 100%; text-align: center;
    line-height: 18px; font-weight: normal; font-size: 16; color: white;}
.fc-visit-list-item-content-left {height: 100%;}
.fc-visit-list-item-content-left-1 {width: 100%; height: 50%; display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center;}
.fc-visit-list-item-content-left-2 {width: 100%; height: 50%; display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center;}
.fc-visit-list-item-content-center {height: 100%;}
.fc-visit-list-item-content-center-1 {width: 100%; height: 60%; display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center;}
.fc-visit-list-item-content-center-2 {width: 100%; height: 40%; display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center;}
.fc-visit-list-item-content-right {height: 100%;}
.fc-visit-list-item-content-right-1 {width: 100%; height: 40%; display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: end; align-items: flex-end;}
.fc-visit-list-item-content-right-2 {width: 100%; height: 60%; display: -ms-flexbox; display: flex; -ms-flex-pack: center; justify-content: center; -ms-flex-align: center; align-items: center;}


  