/* Foundation fix */

.eke-lookup-input-margin input {
    margin: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.eke-lookup-input-margin input:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}