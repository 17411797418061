.wf-sec-station-perm-listview-button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.1rem 0 0;
    font-family: inherit;
    padding: 0.1em 0.2em;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    border: 1px solid transparent;
    border-radius: 3px;
    -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    -o-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
}

.wf-sec-station-perm-listview-perm-active {
    opacity: 1.0;
    color: darkgreen;
}

.wf-sec-station-perm-listview-perm-inactive {
    opacity: 0.2;
}

.wf-sec-station-perm-listview-perm-inactive:hover {
    opacity: 0.35;
}