
.wf-station-constraint-matrix-table-head-row {
    height: 200px; /* ez ugyanannyi amennyit kihagy a tetején */   
}

.wf-station-constraint-matrix-table-master-head {
    height: 60px;
    text-align: right;
    font-size: 0.8rem;
    border: 1px solid black;
    background-color: lightblue;
}

.wf-station-constraint-matrix-table-detail-head {
    width: 60px;
    margin-top: 200px; /* ez ugyanannyi amennyit kihagy a tetején */   
    vertical-align: top;
    text-align: right;
    -webkit-writing-mode: vertical-rl ;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl ;
    -webkit-text-orientation: sideways;
            text-orientation: sideways;
    font-size: 0.8rem;
    border: 1px solid black;
    background-color: lightblue;
}


.wf-station-constraint-matrix-table-master-station {
    width: 60px;
    height: 200px; /* ez ugyanannyi amennyit kihagy a tetején */    
    vertical-align: bottom;
    -webkit-writing-mode: vertical-rl ;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl ;
    -webkit-text-orientation: sideways;
            text-orientation: sideways;
    font-size: 0.8rem;
    border: 1px solid black;
}

.wf-station-constraint-matrix-table-detail-station {
    vertical-align: middle;
    text-align: right;
    font-size: 0.8rem;
    border: 1px solid black;
}

.wf-station-constraint-matrix-table-checkbox-cell {
    text-align: center;
    vertical-align: center;
    padding: 5px 5px 5px 5px !important;
    margin: 2px 2px 2px 2px !important;
}

.wf-station-constraint-matrix-table-checkbox-cell-changed {
    background-color: yellow;
}

.wf-station-constraint-matrix-table-cell > input {
    margin: 0;
}