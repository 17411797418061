.exe-header-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    height: 40px;
    max-width: calc(100% - 110px);
}

.exe-header-tools {
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    -ms-flex-line-pack: center;
        align-content: center;
    -ms-flex-align: center;
        align-items: center;
}

.exe-header-tools .tools-button {
    cursor: pointer;
}

.exe-header-tools .tools-button:not(:last-child) {
    margin-right: 10px;
}

.exe-header-tools .tools-button-close {
    font-weight: 300;
    font-size: 38px;
}

.exe-container-toggletoHidden {
    visibility: hidden;
    overflow: hidden;
    height: 0;
}

.exe-container-toggletoShown {
    display: initial;
}

.container-exercise-title {
    overflow: hidden;
    /*
    max-height: 2.8rem;
      display: block;
          -webkit-box-orient: vertical;
    box-orient: vertical;
text-overflow: ellipsis !important;
white-space: nowrap;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    */
    font-weight: 700;
    font-size: 0.9rem;
}

.container-exercise-title h3.exercise-title {
    margin-bottom: 0;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

.container-exercise-title h3 {
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    margin-bottom: 0;
}

.sub-number {
    background-color: #fff;
    color: #444;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 10px;
}

.sub-number h3 {
    margin-bottom: 0;
    font-weight: bold;
}

.eke-controls-image {
    width: auto;
    height: 40px;
    max-width: 40px;
    margin-right: 5px;
}

.container-exercise-counter {
    overflow: hidden;
    font-weight: 700;
    font-size: 0.9rem;
    padding-left: 5px;
}

.container-exercise-counter h3 {
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    margin-bottom: 0;
}