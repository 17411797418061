@-webkit-keyframes usr-share-dialog-newly-added {
    from { background-color: red; }
    to { background-color: yellow; }
}

@keyframes usr-share-dialog-newly-added {
    from { background-color: red; }
    to { background-color: yellow; }
}

.usr-share-dialog-newly-added {
    -webkit-animation-name: usr-share-dialog-newly-added;
            animation-name: usr-share-dialog-newly-added;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}