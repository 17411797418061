.ansitem label, .ansitem input {
    text-align: center;
}

.imageContainer {
    position: relative;
}

.imageContainer div{
    max-width: 1500px;
    max-height: 700px;
    position: relative;
}

.imagesvg svg{
    position: absolute;
}

.coordimg{
    max-width: 600px;
    max-height: 600px;
}

.answer-item-circle{
    fill-opacity: 0.4;
    stroke: black;
    stroke-width: 3;
    stroke-dasharray: 5,5;
    -ms-flex-line-pack:center;
        align-content:center;
    position: absolute;
}

.currentCircle svg{
    -ms-flex-line-pack:left;
        align-content:left;
}

.small-3{
    max-width: 100%;
    -ms-flex: auto;
        flex: auto;
}

.coordinates {
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
}

#mainPolygon{
    fill: red;
    fill-opacity: 0.6;
    stroke: black;
    stroke-width: 3;
    stroke-dasharray: 5,5;
}

.mainCircle{
    fill: red;
    fill-opacity: 0.6;
}

.helperCircle {
    fill:blue;
    stroke: black;
    stroke-width: 2;
}

.otherPolygon{
    fill-opacity: 0.4;
    stroke: black;
    stroke-width: 3;
    stroke-dasharray: 5,5;
}

.draggables {
    fill: black;
    stroke: #56f3ff;
}

.areas {
    fill: black;
    stroke: #ff5e00;
}