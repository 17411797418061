.swiper,
.swiper-wrapper
{
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
.swiper-wrapper
{
    overflow: hidden
}
.swiper-items
{    
    margin: 0;
    height: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
}
.swiper-item
{
    /* height: 100%; */
    /* display: block; */
    /* float: left; */
    padding: 10px;
    position: relative;
    
}
.swiper-item>.content
{
    height: 100%;
    overflow: hidden;
}


.tile .swiper .prevItem,
.tile .swiper .nextItem
{
    height: 60px;
    padding: 5px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);   
    font-size: 2rem;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
    -ms-flex-align: center;
        align-items: center;
    background-color: #fff;    
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.5);    
            box-shadow: 0 0 3px rgba(0,0,0,0.5);
    -ms-flex-pack: center;
        justify-content: center;
    cursor: pointer;
}


.tile .swiper .prevItem
{
    left: -1rem;
    border-radius: 0 5px 5px 0;
}
.tile .swiper .nextItem
{
    right: -1rem;
    border-radius: 5px 0 0 5px;
}