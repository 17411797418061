.kap-course-sidebar li a i{
    margin-right: 5px;
}
.kap-course-tile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    /* padding: 10px; */
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .6);
    box-shadow: 0 2px 6px rgba(0, 0, 0, .6);
    margin: 10px 5px;
    /* height: auto; */
    width: 100%;
    min-height: 100px;
    position: relative;
    color: rgb(80, 80, 80);
}


.kap-course-tile-logo {
    max-height: 120px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.course-icon-wrapper {
    position: relative;
}
.online-course {
    height: 50px;
    width: 50px;
    border: 1px solid darkgray;
    font-size: 1.4rem;
    background-color: #fff;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    -ms-flex-pack: center;
        justify-content: center;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 80%;
    left: 90%;
    cursor: default;
    background-image: -o-radial-gradient(circle, white, #b9cbff, #006dff);
    background-image: radial-gradient(circle, white, #b9cbff, #006dff);
}

.kap-course-tile-title
{
    -ms-flex:1 1;
        flex:1 1;
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
}

.kap-course-closed {
    background-color: rgb(214, 214, 214);
    color: #313131;
}
.course-tile-inner-div {
    padding: 7px 7px 7px 7px;
    height: auto;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex: 1 1;
    flex: 1 1;
    position: relative;
    z-index: 0;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden; /* required */
}

.course-tile-inner-div > * {
    width: 100%;
}

.course_tile_button_div{
    margin-top: 1rem;
}

.kap-course-tile h3{
    font-family: 'BloggerSansBold';
    font-size: 1.2rem;
}

.kap-course-tile-div{
    margin: 0;
    text-align: left;    
    background-color: transparent;
}
/*
.kap-course-tile-ribbon {
    display: "block";
    width: "170px"; 
    color: white;*/
    /*position: fixed;*/
    /*display: block;*/
  /*  right: -70px;
    top: 66px;
    padding: 10px 0;
    padding-left: 1em;
    text-align: center;
    background-color: yellow;
    transform: rotate(45deg);
    border: 3px dashed #000;
    z-index: 10;
}*/


.kap-course-tile-ribbon {
    margin: 0;
    padding: 0;
    background-color: rebeccapurple;
    color:white;
    position: absolute;
    top:-22px;
    right:-22px;
    -webkit-transform: translateX(30%) translateY(0%) rotate(45deg);
        -ms-transform: translateX(30%) translateY(0%) rotate(45deg);
            transform: translateX(30%) translateY(0%) rotate(45deg);
    -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
            transform-origin: top left;
    width: 155px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
            box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  }
  

.kap_course_download_div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: left;
    margin: 10px;
}

.kap-course-downloadable::before{
    content:"📄";
}

.kap-course-list-div{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: center;
        justify-content: center;
}

.kap-course-list-div > div{
    width: 100%;
    min-width: 200px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
}

@media (min-width: 640px) {
    .kap-course-list-div > div {
        /* width: calc(100% / 2 - 20px); */
    }

    .SplitPane.disabled .kap-course-list-div > div {
        width: calc(100% / 2 - 20px);
    }
}

@media (min-width: 1024px) {
    .kap-course-list-div > div {
        width: calc(100% / 2 - 20px);
    }

    .SplitPane.disabled .kap-course-list-div > div {
        width: calc(100% / 3 - 20px);
    }
}

@media (min-width: 1200px) {
    .kap-course-list-div > div {
        width: calc(100% / 3 - 20px);
    }

    .SplitPane.disabled .kap-course-list-div > div {
        width: 330px;
    }
}

@media (min-width: 1440px) {
    .kap-course-list-div > div {
        width: 330px;
    }
}

.trainer-invitation-response-div{
    background-color: white;
    padding: 10px;
}

.trainer-invitation-response-div{    
    background-color: white;
    padding: 10px;
}
.trainer-invitation-response-div .answer-comment-time{
    color: darkgray;
}
.trainer-invitation-response-div p{
    border: #eee solid 1px;
    padding: 5px;
    border-radius: 10px;    
    background-color: #eee;
}

.student-list-subcomponent{
    -webkit-box-shadow: 
        inset 0px 11px 8px -10px #CCC,
        inset 0px -11px 8px -10px #CCC;
            box-shadow: 
        inset 0px 11px 8px -10px #CCC,
        inset 0px -11px 8px -10px #CCC; 
    background-color: white;
    padding: 10px;
}


