.image-selector {
    display: -ms-flexbox;
    display: flex;  
    -ms-flex-wrap: wrap;  
        flex-wrap: wrap;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -ms-flex-align: end;
        align-items: flex-end;
  }
.btn-right-pad-mfc
{
    margin-right: 2px;
    margin-top: 6px!important;
}
.btn-top-pad-mfc
{
    margin-top: 6px!important;
}
.btn-left-pad-mfc
{
    margin-left: 1rem;
}
.mfc-keyword-label {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
  }
.mfc-keyword-checkbox {
   margin-top: 0.5rem!important;
  }
.image-selector__wrapper {
    width: 150px;
    max-height: 130px;
    display: inline-block;
    padding: 6px;
    margin: 2px;
    text-align: center;
    vertical-align: bottom;
}
.image-selector__selected {
    background-color:#2bb473 ;
    opacity: 0.8;
}
.image-selector__unselected {
    opacity: 1;
    background-color: none;
}
.image-selector__wrapper--is-selectable:hover {
    cursor: pointer;
    background-color: lightgray;
}
.image-selector__wrapper--is-large {
    width: 250px;
    max-height: 230px;
}

.image-selector__name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    font-size: smaller;
}

.image-selector__thumbnail {
    display: block;
    max-width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    margin: 0 auto;
    -webkit-box-shadow: 2px 2px 4px gray;
            box-shadow: 2px 2px 4px gray;
    background: white;
}
.image-selector__thumbnail--notembedded { 
     height: 78px;  
}
.image-select__breadcrumb-item {
    font-size: small !important;
}
.image-select-panels-small
{
    /* line-height: 1!important; */
     height: 2.4375rem; 
}
.tabs {
    margin: 0;
     border: 0px;
    background: #fefefe;
    list-style-type: none;
}
.button.warning
{
    color:#000;
}

.eke-mediachooserPagination
{
    width: 5rem!important;
    margin-right: 5px;
    line-height: 1.85;
    padding-left: 0.95rem
}
.eke-video-linktoBook a
{
    color:#000;
}
.eke-video-linktoBook a:hover
{
    color:#2bb473 ;
}
.eke-mfc-tooltip {
    position: relative;
    display: inline-block;
}
.eke-mfc-tooltip .eke-mfc-tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: #2bb473 ;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px 0;
    position: absolute;
    z-index: 1;
    left: 125%;
    margin-left: 0px;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.eke-mfc-tooltip .eke-mfc-tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 70%;
    left: -6%;
    margin-left: 0px;
    border-width: 6px;
    border-style: solid;
    border-color: #2bb473 transparent transparent transparent;
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(91deg); 
}
.eke-mfc-tooltip:hover .eke-mfc-tooltiptext {
    visibility: visible;
    opacity: 1;
}

.html-editor-embed-dropzone {
    height: 100%;
    width: 100%;
    border: none;
}

.html-editor-embed-dropzone-drag-over {
    background-color: #9DA6BB;
    border: #4F5F84 3px dashed;
}

.dropzone-empty {
    height: 200px;
    width: 95%;
    -ms-flex-line-pack: center;
        align-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border: #2ba6cb 2px dashed;
}

.empty-dropzone-content {
    color: #3ba8dd;
    height: 100%;
    width: 100%;
    text-align: center;    
}
.mfc-quicktagger-scroll{
    height: 80vh;
    overflow: auto;
    visibility: hidden;
}

.mfc-quicktagger-scroll:hover{
    visibility: visible;
}
.mfc-quicktagger-wrapper{
    visibility: visible;
}