.original-text p {
    cursor: pointer;
}

.original-text p:hover {
    background: rgba(0, 0, 0, 0.1);
}

.original-text .original-text--added {
    background-color: lightgreen;
}
