.share-data-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 20px;
}

.share-data-title > h4 {
    margin-left: 20px;
}

.share-data-card-header {
    font-weight: bold;
}

.share-data-rows {
    margin-bottom: 10px;
}

.share-page-icon {
    width: 40px;
    margin-right: 10px;
}