.attandanceNewEventDialogTitle {background-color: lightskyblue; text-align: center; font-size: 20px; font-weight: bold;}
.attandanceNewEventDialogContent {overflow: inherit !important;}

.attendance-top-button, .attendance-top-button:focus {
    font-family: 'Montserrat-Light';
    font-size: 13px;
    font-weight: bolder;
    color: white;
    text-transform: uppercase;
    display: inline-block;
    padding: 1em;
}

.attendance-top-button:hover {
    background: #106291;
    color: #ddd;
}

.attendance-top-button.active {
    background: #106291;
    text-decoration: underline;
}

.attendance-event-nav a {
    display: block;
    background: #106291;
    color: white;
    padding: 1em;
}

.attendance-event-nav a.active, .attendance-event-nav a:hover {
    background: #154e79;
}

/*
a.attendance-event-nav-1 {
    background: #911c50;
}
a.attendance-event-nav-2 {
    background: #144e78;
}
a.attendance-event-nav-3 {
    background: #2cacc9;
}
a.attendance-event-nav-4 {
    background: #4ab9a8;
}
a.attendance-event-nav-5 {
    background: #6abc84;
}
a.attendance-event-nav-6 {
    background: #fdc443;
    color:black;
}
a.attendance-event-nav-7 {
    background: #f1861e;
    color:black;
}
a.attendance-event-nav-8 {
    background: #cd463a;
}
a.attendance-event-nav-9 {
    background: #b72647;
}
*/
