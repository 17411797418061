.imagesettings__preview figure.float-right.quarter {
    max-width: 25%;
}

.imagesettings__preview figure.float-left.quarter {
    max-width: 25%;
}

.imagesettings__preview figure.float-right.third {
    max-width: 33%;
}

.imagesettings__preview figure.float-left.third {
    max-width: 33%;
}

.imagesettings__preview figure.float-right.half {
    max-width: 50%;
}

.imagesettings__preview figure.float-left.half {
    max-width: 50%;
}

.imagesettings__preview img {
    -max-width: 300px;
    -max-height: 300px;
}
