
.lowgrade-menu{
    margin: 10px auto; 
    position: absolute;
    z-index: 1000;
    width: 100%;
    max-width: 768px;
}

.lowgrade-menu .column{
    margin: 0;
    padding: 0;
}

.lowgrade-menu button{
    border-radius: 50%;
    margin: 10px auto;
    display: block;  
    position: relative;
    cursor: pointer;
    outline: none;
}
#mapContent
{
   position: fixed;
   top:0px;
   bottom: 0px;
   width: 100%;
}

#mapArea
{
    margin: 0;
    height: 100%;
    max-width: 768px;
    min-width: 320px;
    width: 100%;
    position: relative;
}

.map-menu-container
{
    margin: 0 auto;
    position: relative;
    max-width: 100%;
}

.map-grid-container
{
    position: relative;
    top: 0;
    bottom: 0;
    height: 100%;

}

.map-grid-container .map-img
{
    position: absolute;
    top:0;
    height: 100%;
    max-width: none;
    z-index: -1;
}

.map-area
{
    margin: 0 auto;
    height: 100%;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    position: relative;
}

.map-grid 
{
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    height: 100%;
    -ms-flex-direction: column;
        flex-direction: column;
    bottom: 0;
}

.map-grid .grid-row
{
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.map-slide
{
    -ms-flex: 1 1;
        flex: 1 1;
}

.map-grid .row
{
    height: 100%;
    width: 100%;
}

.map-grid .cell
{
    border: 0px solid rgba(0,0,0, 0.2);;
    height: 100%;
    -ms-flex: 1 1;
        flex: 1 1;
    position: relative;
    z-index: 100;
}

.map-grid .cell .state
{
    background-color: #36aa5f;
    color: #fff;
    font-weight: 700;
    z-index: 10;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100%;
    cursor: pointer;
    border: 2px solid #0669E2;
    text-shadow: 0 0 3px #333;
    font-size: large;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#87e0fd+0,53cbf1+40,05abe0+100;Blue+3D+%23+16 */
    background: #87e0fd; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(#87e0fd),color-stop(40%, #53cbf1),to(#05abe0));
    background: -o-linear-gradient(top, #87e0fd 0%,#53cbf1 40%,#05abe0 100%);
    background: linear-gradient(to bottom, #87e0fd 0%,#53cbf1 40%,#05abe0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87e0fd', endColorstr='#05abe0',GradientType=0 ); /* IE6-9 */
}
.map-grid .cell .state-text
{
    position: absolute;
    top:-15px;
    width: 125px;
    line-height: 1.2em;
    -moz-user-select: -moz-none;
   -webkit-user-select: none;
   /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}

.map-grid .cell .state:hover
{
    background-color: #4ac776;
}

.map-nav #prevBtn,
.map-nav #nextBtn
{
    position: fixed;
    z-index: 150;
    background-color: mediumseagreen;
    border: 0px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: -25px;
    margin: 10px auto;
    display: block;
    -webkit-box-shadow: 0 0 0 5px rgba(0,0,0, 0.2);
            box-shadow: 0 0 0 5px rgba(0,0,0, 0.2);
    color: #fff;
    font-size: 1.6em;
    cursor: pointer;
}
.map-nav #prevBtn:hover,
.map-nav #nextBtn:hover
{
    -webkit-box-shadow: 0 0 0 8px rgba(0,0,0, 0.2);
            box-shadow: 0 0 0 8px rgba(0,0,0, 0.2); 
}
.map-nav #prevBtn
{
    left: 15px;
    top: 50%;
}

.map-nav #nextBtn
{
    right: 15px;
    top: 50%;
}

#exeContent
{
    z-index: 1000;
}

.exe-container
{
    /* background: rgba(255, 255, 255, 0.9); */
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 10;
    overflow: hidden;
    background-color: transparent;
}

.exe-area
{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.exe-area .lowgrade-menu
{
    position: relative;
}

.exe-area .shadow-box
{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.3);
    z-index: 1;
}

.map-hide,
.exe-hide {
	visibility: hidden;
}
.map-show,
.exe-show {
	visibility: visible;
}

.map-effect
{	
    -webkit-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transform: scale(0.9);

	-webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.map-show.map-effect {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}


/* Effect 1: Fade in and scale up */
.exe-effect-1 {
	-webkit-transform: scale(0.7);
	-ms-transform: scale(0.7);
	transform: scale(0.7);
	opacity: 0;
	-webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.exe-show.exe-effect-1 {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
    opacity: 1;
}


/* exe-star */
#star-five {
    font-size: 172px;
    color: orange;

 }
/* #star-five {
    margin: 50px 0;
    position: absolute;
    display: block;
    color: orange;
    width: 0px;
    height: 0px;
    border-right:  100px solid transparent;
    border-bottom: 70px  solid orange;
    border-left:   100px solid transparent;
    -moz-transform:    rotate(35deg);
    -webkit-transform: rotate(35deg);
    -ms-transform:     rotate(35deg);
    -o-transform:      rotate(35deg);
 }
 #star-five:before {
    border-bottom: 80px solid orange;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -45px;
    left: -65px;
    display: block;
    content: '';
    -webkit-transform: rotate(-35deg);
    -moz-transform:    rotate(-35deg);
    -ms-transform:     rotate(-35deg);
    -o-transform:      rotate(-35deg);
 
 }
 #star-five:after {
    position: absolute;
    display: block;
    color: orange;
    top: 3px;
    left: -105px;
    width: 0px;
    height: 0px;
    border-right: 100px solid transparent;
    border-bottom: 70px solid orange;
    border-left: 100px solid transparent;
    -webkit-transform: rotate(-70deg);
    -moz-transform:    rotate(-70deg);
    -ms-transform:     rotate(-70deg);
    -o-transform:      rotate(-70deg);
    content: '';
 } */
         

/* exe-star effect */
.get-star
{
    -webkit-animation-name: getStar;
            animation-name: getStar;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}
.get-3-star
{
    -webkit-animation-name: getStar;
            animation-name: getStar;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 3;
            animation-iteration-count: 3;
}
.exe-star
{
    -webkit-transform: scale(0.3);
        -ms-transform: scale(0.3);
            transform: scale(0.3);
    position: absolute;
    z-index: 10000;
    
}
/* Standard syntax */
@-webkit-keyframes getStar {
    0%   { left:50%; margin-left:-80px; top:50%;-webkit-transform: scale(0.3);transform: scale(0.3); opacity: 0.1}
    30%   { opacity: 1}
    70% { left:50%; margin-left:-80px; top:-80px;-webkit-transform: scale(0.3);transform: scale(0.3);}
    100% { left:50%; margin-left:-80px; top:-45px;-webkit-transform: scale(0.1);transform: scale(0.1);}
    
}
@keyframes getStar {
    0%   { left:50%; margin-left:-80px; top:50%;-webkit-transform: scale(0.3);transform: scale(0.3); opacity: 0.1}
    30%   { opacity: 1}
    70% { left:50%; margin-left:-80px; top:-80px;-webkit-transform: scale(0.3);transform: scale(0.3);}
    100% { left:50%; margin-left:-80px; top:-45px;-webkit-transform: scale(0.1);transform: scale(0.1);}
    
}

.p-background
{
    position: absolute;
    top:-100px;
}
.p-background img
{
    max-width: none;
    -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
            transform: scale(0.7);
}
