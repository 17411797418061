.usr-like,
.like-block button {
    cursor: pointer;
    margin-left: 1em;
}
.usr-like-inverse {
    cursor: pointer;
    margin-left: 1em;
    color:white;
}
.like {
    color: #1368e7;
}

.favourite {
    color: #4ab685;
}

.rate {
    color: #FFA93D;
}

.rating-bar {
    border: rgba(77, 161, 216, 0.7) 3px solid;
    max-width: 300px;
    border-radius: 20px;
    padding: 2px;
}

.rate-elements {
    margin-left: auto;
    margin-right: auto;
}