.DateDisabled_NKP::-webkit-inner-spin-button {-webkit-appearance: none;  display: none;}
.DateDisabled_NKP::-webkit-clear-button {-webkit-appearance: none; display: none;}
.DateDisabled_NKP::-webkit-calendar-picker-indicator {-webkit-appearance: none; display: none;}
.TimeDisabled_NKP::-webkit-inner-spin-button {-webkit-appearance: none;  display: none;}
.TimeDisabled_NKP::-webkit-clear-button {-webkit-appearance: none; display: none;}

.DateNoSpin_NKP::-webkit-inner-spin-button {-webkit-appearance: none;  display: none;}
.DateNoClear_NKP::-webkit-clear-button {-webkit-appearance: none; display: none;}
.TimeNoSpin_NKP::-webkit-inner-spin-button {-webkit-appearance: none;  display: none;}
.TimeNoClear_NKP::-webkit-clear-button {-webkit-appearance: none; display: none;}

.DateNoClear_NKP::-moz-clear-button {-moz-appearance: none; appearance: none; display: none;}
.DateNoClear_NKP::-ms-clear {display: none;}
.TimeNoClear_NKP::-moz-clear-button {-moz-appearance: none; appearance: none; display: none;}
.TimeNoClear_NKP::-ms-clear {display: none;}

