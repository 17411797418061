.lp-button-icon{
    width: 20px;
    max-height: 30px;
}

.lp-field-label{
    margin: 10px 0;
    font-size: 1.3rem;
    line-height: 1;
}

.lp-button-group{
    margin-top: 20px;
}

.lp-container-helper-div {
    margin: auto;
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.lp-container-wrapper {
    padding-top: 1rem;
    min-width: 52.5rem;
    max-width: 62.5rem;
    margin-left: auto;
    margin-right: auto;
}

.lp-container-timer {
    list-style: none;
    margin: 1em;
}

.lp-container-timer-elem {
    float: right;
    margin-right: 0.3em;
    color: #0a0a0a;
    cursor: default;
}

.lp-condtion-card {
    overflow: visible;
}