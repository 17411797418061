.oo-folder-permtype-checkbox-cell {
    text-align: center;
    vertical-align: center;
    padding: 5px 5px 5px 5px !important;
    margin: 2px 2px 2px 2px !important;
}

.oo-folder-permtype-checkbox-cell > input {
    margin: 0;
}

.oo-folder-perm-table-head-row {
    height: 200px;
}

.oo-folder-perm-table-rotate {
    width: 40px;
    height: 200px;
    vertical-align: top;
    text-align: left;
    -webkit-writing-mode: vertical-rl ;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl ;
    -webkit-text-orientation: sideways;
            text-orientation: sideways;
    font-size: 0.8rem;
    border: 1px solid black;
}

.oo-folder-permtype-owner {
    background-color: darkkhaki;
}

.oo-folder-permtype-owner-changed {
    font-weight: bold;
}

.oo-folder-permtype-owner-changed-icon {
    padding-right: 10px;
    color: red;
}


.oo-folder-permtype-folder {
    background-color: aquamarine;
}

.oo-folder-permtype-contained {
    background-color: azure;
}

.oo-folder-permtype-file {
    background-color: lightsalmon;
}

.oo-folder-permtype-check-all-perms {
    cursor: pointer;
    color: lightgray;
}

.oo-folder-permtype-check-all-perms:hover {
    color: black;
}