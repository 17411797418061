ul.permlisteditor-permownerlist {
    list-style-type: none;
}

.permlisteditor-button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.1rem 0 0;
    font-family: inherit;
    padding: 0.1em 0.2em;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    border: 1px solid transparent;
    border-radius: 3px;
    -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    -o-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #2ba6cb;
    color: #fefefe;
}

.permlisteditor-button-alert {
    background-color: #cc4b37;
    color: #fefefe;
}


.permlisteditor-button-warning {
    background-color: #ffae00;
    color: #0a0a0a;
}


.permlisteditor-button-success {
    background-color: #3adb76;
    color: #0a0a0a;
}


.permlisteditor-button-secondary {
    background-color: #767676;
    color: #f3f3f3;
}

.permlisteditor-button-primary {
    background-color: #1779ba;
    color: #f3f3f3;
}

