div .sec-user-session-item {
    background-color: antiquewhite;
    border: 1px solid black;
}

div .sec-user-session-item:hover {
    background-color: lightgray;
}

.user-view-sessions-small {
    font-size: small;
}

.user-view-sessions-school {
    text-align: right;
}